var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.prescriptionsHeaders,"items":_vm.prescriptions,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"search":_vm.search,"item-key":"collectionGuid","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","label":_vm.$t('search'),"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mt-5",attrs:{"label":_vm.$t('procedures.onlyThisSession')},model:{value:(_vm.onlyThis),callback:function ($$v) {_vm.onlyThis=$$v},expression:"onlyThis"}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('tr',[_c('td',{class:_vm.$vuetify.breakpoint.xsOnly ? 'd-none' : ''}),_c('td',[_vm._v(" "+_vm._s(item.prescriptionNumber)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.doctor.doctorName)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReg,"YYYY/MM/DD "))+" ")]),_c('td',{staticStyle:{"min-width":"140px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.printItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-printer ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("print")))])]),(_vm.isInRole('34') && _vm.doctorGuid == item.doctor.guid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]):_vm._e(),(_vm.isInRole('35') && _vm.doctorGuid == item.doctor.guid)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])]):_vm._e()],1),_c('td',{staticStyle:{"width":"80px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v(_vm._s(isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1)])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-card',{staticStyle:{"display":"table-row"},attrs:{"flat":"","id":"printMe"}},[_c('td',{staticClass:"v-card__text pa-4",attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"elevation-2 pa-2",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((Object.values(
                      _vm.prescriptionDetailHeaders
                    )),function(i,index){return _c('th',{key:index},[_vm._v(" "+_vm._s(i.text)+" ")])}),0)]),_c('tbody',_vm._l((item.masterDetail),function(item1,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item1["itemName"]))]),_c('td',[_vm._v(_vm._s(item1["brandName"]))]),_c('td',[_vm._v(_vm._s(item1["dose"]))]),_c('td',[_vm._v(_vm._s(item1["usageItem"]))]),_c('td',[_vm._v(_vm._s(item1["note"]))])])}),0)]},proxy:true}],null,true)})],1)]),_c('td',{attrs:{"colspan":headers.length}},[_c('v-divider')],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }