<template>
  <v-card flat>
    <v-card-text class="px-0 pt-7">
      <v-row no-gutters>
        <v-col>
          <v-form
            ref="patientHistoryForm"
            v-model="patientHistoryValid"
            lazy-validation
          >
            <v-row>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.initialDiagnosis"
                  :label="$t('patientHistory.initialDiagnosis')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.finalDiagnosis"
                  :label="$t('patientHistory.finalDiagnosis')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.chiefComplaintAndDuration"
                  :label="$t('patientHistory.chiefComplaintAndDuration')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.historyOfPresentIllness"
                  :label="$t('patientHistory.historyOfPresentIllness')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.medicalAndSurgicalHistory"
                  :label="$t('patientHistory.medicalAndSurgicalHistory')"
                ></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.drugsHistoryAndDrugsAllergy"
                  :label="$t('patientHistory.drugsHistoryAndDrugsAllergy')"
                ></v-textarea>
              </v-col>

              <v-col cols="12" md="12">
                <v-textarea
                  rows="3"
                  v-model="patientHistoryModel.note"
                  :label="$t('notes')"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-btn
              color="green darken-1"
              class="white--text"
              @click="save"
              :disabled="!patientHistoryValid"
              :min-width="100"
            >
              <v-icon>mdi-content-save-outline</v-icon>
              {{ $t("save") }}
            </v-btn>

            <v-btn
              color="blue darken-1"
              class="white--text"
              @click="cancelEdit"
              :class="isEdit ? '' : 'd-none'"
            >
              <v-icon>mdi-newspaper-variant-outline</v-icon>
              {{ $t("new") }}
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn to="/patientschedule" color="" text>
              {{ $t("backToList") }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["patientGuid", "editPatientHistoryModel"],
  watch: {
    editPatientHistoryModel(model) {
      console.log(model);
      this.isEdit = true;
      this.patientHistoryModel = { ...model };
    },
  },
  data() {
    return {
      patientHistoryValid: true,
      patientHistoryModel: {
        patientGuid: this.patientGuid,
      },
      isEdit: false,
    };
  },
  methods: {
    cancelEdit() {
      this.patientHistoryModel = Object.assign({}, {});
      this.isEdit = false;
    },
    save() {
      //this.patientHistoryModel.patientGuid = this.patientGuid;
      if (this.isEdit) {
        axios
          .post("PatientHistory/Update", this.patientHistoryModel)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.patientHistoryModel = Object.assign({}, {});
              this.$toast(this.$t("operationAccomplishedSuccessfully"));
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
            this.$emit("savePatientHistory");
            this.isEdit = false;
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      } else {
        axios
          .post("PatientHistory/Add", this.patientHistoryModel)
          .then((response) => {
            if (response.data.status == "Successful") {
              this.patientHistoryModel = Object.assign({}, {});

              this.$toast(this.$t("operationAccomplishedSuccessfully"));
            } else {
              this.$toast.error(this.$t("error." + response.data.message));
            }
            this.$emit("savePatientHistory");
          })
          .catch((e) => {
            this.$toast.error(this.$t("AnErrorOccurredDuringTheProcess"));
            console.log(e);
          });
      }
    },
  },
};
</script>

<style></style>
