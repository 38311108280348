<template>
  <v-card flat>
    <v-data-table
      :headers="prescriptionsHeaders"
      :items="prescriptions"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :search="search"
      item-key="collectionGuid"
      show-expand
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            single-line
            :label="$t('search')"
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-switch
            v-model="onlyThis"
            :label="$t('procedures.onlyThisSession')"
            class="mt-5"
          ></v-switch>
          <confirm-dialog
            :openDialog="dialogDelete"
            :onClicked="deleteItemConfirm"
            :onClose="closeDelete"
          ></confirm-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item="{ item, expand, isExpanded }">
        <tr>
          <td :class="$vuetify.breakpoint.xsOnly ? 'd-none' : ''"></td>
          <td>
            {{ item.prescriptionNumber }}
          </td>
          <td>
            {{ item.doctor.doctorName }}
          </td>
          <td>
            {{ item.dateReg | moment("YYYY/MM/DD ") }}
          </td>
          <td style="min-width:140px">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="printItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-printer
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("print") }}</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="isInRole('34') && doctorGuid == item.doctor.guid"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="editItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("edit") }}</span>
            </v-tooltip>
            <v-tooltip
              top
              v-if="isInRole('35') && doctorGuid == item.doctor.guid"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon @click="deleteItem(item)" v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("delete") }}</span>
            </v-tooltip>
          </td>
          <td style="width: 80px">
            <v-btn icon @click="expand(!isExpanded)">
              <v-icon>{{
                isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
              }}</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <v-card flat style="display: table-row" id="printMe">
          <td class="v-card__text pa-4" :colspan="headers.length">
            <v-simple-table class="elevation-2 pa-2">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(i, index) in Object.values(
                        prescriptionDetailHeaders
                      )"
                      :key="index"
                    >
                      {{ i.text }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item1, index) in item.masterDetail" :key="index">
                    <td>{{ item1["itemName"] }}</td>
                    <td>{{ item1["brandName"] }}</td>
                    <td>{{ item1["dose"] }}</td>
                    <td>{{ item1["usageItem"] }}</td>
                    <td>{{ item1["note"] }}</td>

                    <!-- <td
                      v-for="(i, index1) in Object.values(item1)"
                      :key="index1"
                    >
                      {{ i }}
                    </td> -->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </v-card>

        <td :colspan="headers.length">
          <v-divider />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";

export default {
  components: { ConfirmDialog },
  props: ["guid", "refreshPrescriptionTable"],
  data() {
    return {
      expanded: [],
      singleExpand: false,
      search: "",
      onlyThis: true,
      dialogDelete: false,
      file: null,
      prescriptionsHeaders: [
        {
          text: this.$t("prescriptions.prescriptionNumber"),
          value: "prescriptionNumber",
        },
        { text: this.$t("doctors.doctorName"), value: "doctor.doctorName" },
        { text: this.$t("date"), value: "dateReg" },
        { text: "", value: "actions" },
        { text: "", value: "" },
      ],

      prescriptionDetailHeaders: [
        { text: this.$t("prescriptions.itemName"), value: "itemName" },
        { text: this.$t("prescriptions.brandName"), value: "brandName" },
        { text: this.$t("prescriptions.dose"), value: "dose" },
        { text: this.$t("prescriptions.usageItem"), value: "usageItem" },
        { text: this.$t("notes"), value: "note" },
      ],
      prescriptions: [],
      editedItem: {},
    };
  },
  watch: {
    onlyThis() {
      this.refreshTable();
    },
    refreshPrescriptionTable() {
      this.refreshTable();
    },
  },
  created() {
    this.refreshTable();
  },

  methods: {
    editItem(item) {
      this.$emit("editPrescription", item);
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    printItem(item) {
      axios({
        url: "Prescription/Print?guid=" + item.collectionGuid,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },

    deleteItemConfirm() {
      axios
        .delete("Prescription/Delete?guid=" + this.editedItem.collectionGuid)
        .then((response) => {
          this.$emit("deletePresecription", response);
          this.refreshTable();
        })
        .catch((e) => {
          this.type = "error";
          this.message = "Something Went Wrong!";
          console.log(e);
        });

      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, {});
      });
    },
    refreshTable() {
      axios
        .get(`Prescription/Get?guid=${this.guid}&&onlyThis=${this.onlyThis}`)
        .then((response) => {
          this.prescriptions = response.data.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
