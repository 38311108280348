var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","width":"100%"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"guid"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","single-line":"","label":_vm.$t('search'),"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"mt-5",attrs:{"label":_vm.$t('procedures.onlyThisSession')},model:{value:(_vm.onlyThis),callback:function ($$v) {_vm.onlyThis=$$v},expression:"onlyThis"}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end",staticStyle:{"width":"80px"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)]}},{key:"item.dateReg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.dateReg,"YYYY/MM/DD"))+" ")]}},{key:"item.initialDiagnosis",fn:function(ref){
var item = ref.item;
return [(item.initialDiagnosis && item.initialDiagnosis.length > 30)?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.initialDiagnosis.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.initialDiagnosis,"label":_vm.$t('patientHistory.initialDiagnosis')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.initialDiagnosis)+" ")])]}},{key:"item.finalDiagnosis",fn:function(ref){
var item = ref.item;
return [(item.finalDiagnosis && item.finalDiagnosis.length > 30)?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.finalDiagnosis.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.finalDiagnosis,"label":_vm.$t('patientHistory.finalDiagnosis')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.finalDiagnosis)+" ")])]}},{key:"item.chiefComplaintAndDuration",fn:function(ref){
var item = ref.item;
return [(
          item.chiefComplaintAndDuration &&
            item.chiefComplaintAndDuration.length > 30
        )?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.chiefComplaintAndDuration.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.chiefComplaintAndDuration,"label":_vm.$t('patientHistory.chiefComplaintAndDuration')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.chiefComplaintAndDuration)+" ")])]}},{key:"item.drugsHistoryAndDrugsAllergy",fn:function(ref){
        var item = ref.item;
return [(
          item.drugsHistoryAndDrugsAllergy &&
            item.drugsHistoryAndDrugsAllergy.length > 30
        )?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.drugsHistoryAndDrugsAllergy.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.drugsHistoryAndDrugsAllergy,"label":_vm.$t('patientHistory.drugsHistoryAndDrugsAllergy')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.drugsHistoryAndDrugsAllergy)+" ")])]}},{key:"item.historyOfPresentIllness",fn:function(ref){
        var item = ref.item;
return [(
          item.historyOfPresentIllness &&
            item.historyOfPresentIllness.length > 30
        )?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.historyOfPresentIllness.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.historyOfPresentIllness,"label":_vm.$t('patientHistory.historyOfPresentIllness')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.historyOfPresentIllness)+" ")])]}},{key:"item.medicalAndSurgicalHistory",fn:function(ref){
        var item = ref.item;
return [(
          item.medicalAndSurgicalHistory &&
            item.medicalAndSurgicalHistory.length > 30
        )?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.medicalAndSurgicalHistory.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.medicalAndSurgicalHistory,"label":_vm.$t('patientHistory.medicalAndSurgicalHistory')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.medicalAndSurgicalHistory)+" ")])]}},{key:"item.note",fn:function(ref){
        var item = ref.item;
return [(item.note && item.note.length > 30)?_c('v-menu',{attrs:{"close-on-content-click":false,"top":"","transition":"scale-transition","width":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '84%' : '450',"offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.note.substring(25, 1) + " ...")+" ")])]}}],null,true)},[_c('v-card',[_c('v-card-text',[_c('v-textarea',{attrs:{"autocomplete":"email","readonly":"","hide-details":"","value":item.note,"label":_vm.$t('notes')}})],1)],1)],1):_c('span',[_vm._v(" "+_vm._s(item.note)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }